<template>
	<div v-if="pageShow">
		<div class="col cc header" ref="header">
			<div class="rmb">￥{{ userInfo.money }}</div>
			<div class="label">历史收益</div>
			<div class="desc">回答现金悬赏问题，被采纳所获得的利益，企业付款直接到微信账号</div>
			<div class="row cc rule" @click="navRule()">活动规则</div>
		</div>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': minHeight }" v-if="minHeight">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<div class="list">
					<template v-for="(item, index) in list">
						<div class="row cb item">
							<div class="row left">
								<div class="col info">
									<div class="title text-ellipsis">{{ item.remark }}</div>
									<div class="desc text-ellipsis">{{ item.create_time_text }}</div>
								</div>
							</div>
							<div class="right">
								<div class="row cc num">{{ item.change }}</div>
							</div>
						</div>
					</template>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
export default {
	data() {
		return {
			page: 0,
			pageSize: 15,
			list: [],
			loading: false,
			finished: false,
			refreshing: false,
			userInfo: {},
			pageShow: false,
			minHeight: ''
		};
	},
	async created() {
		this.$toast.loading({
			duration: 0
		});
		let userInfo = await this.$service.post('user_info/detail');
		this.userInfo = userInfo.data;
		this.pageShow = true;
		this.$toast.clear();
		this.$nextTick(() => {
			this.minHeight = `calc(100vh - ${this.$refs['header'].offsetHeight}px)`;
		});
	},
	methods: {
		navRule() {
			this.$router.push({
				path: '/income/rule'
			});
		},
		onLoad() {
			if (this.refreshing) {
				this.list = [];
				this.refreshing = false;
			} else {
				console.log('onLoad');
				this.loading = true;
				this.page++;
				let url = 'point/amountLog';
				this.$service
					.post(url, {
						page: this.page,
						pageSize: this.pageSize
					})
					.then(res => {
						this.list = this.list.concat(res.data.data);
						this.loading = false;
						if (res.data.data.length < this.pageSize) {
							this.finished = true;
						}
					});
			}
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.finished = false;
			this.refreshing = true;
			this.onLoad();
		}
	}
};
</script>

<style scoped lang="less">
@import './income.less';
</style>
